<template>
    <custom-table
        :filter_settings="UserFilters"
        :filter_items="filterItems"
        :page_filters="page_filters"
        :filters="filters"
        :headers_items="headerItems"
        :toolbar_settings="toolbarSettings"
        :links="links"
        :loading="loadingTable || !items"
        :content-type="contentType"
        :items="items"
        :options="tableOptions"
        :list="rolesList"
        @getData="getList($event)"
        @selectAllCheckbox="selectAllCheckbox($event)"
        @action="updateUserRole"
    />
</template>

<script>
import contentBaseMixin from '@/mixins/content/contentBaseMixin'
import { headerItems } from '@/helpers/constants/user-page/user-table-header-items'
import { UserFilters } from '@/helpers/filters-settings/user-filters'
import { FilterItems, Filters } from '@/helpers/constants/user-page/filters'
import { toolbarSettings } from '@/helpers/constants/user-page/toolbar-settings'

import { mapContentListFields } from '@/store/modules/permissions/permissions-list'
import { createNamespacedHelpers } from 'vuex'

import { eventBus } from '@/helpers/event-bus'

import {
    ACTION_GET_USERS_LIST,
    UPDATE_USER_ROLES
} from '@/store/modules/permissions/permissions-list/action-types'
import { debounce, isEqual } from 'lodash';

const {
    mapActions: mapContentListActions
} = createNamespacedHelpers('permissionsList');

export default {
    name: 'UserPages',
    mixins: [contentBaseMixin],
    data() {
        return {
            tableOptions: {
                filters: true,
                content: true,
                toolbar: true,
                hiddenModals: true
            },
            currentRoute: 'posts-list-users',
            filters: {}
        }
    },
    computed: {
        ...mapContentListFields([
            'users',
            'loading',
            'fullRoles'
        ]),

        headerItems() {
            return headerItems
        },
        allToolbarSettings() {
            return toolbarSettings
        },
        UserFilters() {
            return UserFilters
        },
        filterItems() {
            return FilterItems
        },
        items() {
            return this.users
        },
        loadingTable() {
            return this.loading
        },
        links() {
            return []
        },
        rolesList() {
            return this.fullRoles
        },
        page_filters() {
            return Filters
        }
    },
    watch: {
        '$route.query': debounce(async function (val) {
            if (this.$route.name !== 'posts-list-users') return
            if (isEqual(this.$route.query, this.filters)) return
            this.filters = {
                ...val
            }
            await this.getList(val)
        }, 50)
    },
    mounted() {
        if (Object.values(this.$route.query).length !== 0) {
            this.filters = { ...this.$route.query }
        } else {
            this.filters = { ...Filters }
        }
        this.getList(this.filters)
        this.$bus.$on('updateUsers', () => this.getList(this.filters))
    },
    methods: {
        ...mapContentListActions([
            ACTION_GET_USERS_LIST,
            UPDATE_USER_ROLES
        ]),
        getList(query = {}) {
            this[ACTION_GET_USERS_LIST]({ ...query })
        },
        async updateUserRole(action) {
            await this[UPDATE_USER_ROLES]({ id: action.data.row.id, roles: action.data.checked.map((item) => item.id) })
            this.getList(this.$route.query)
            eventBus.$emit('showSnackBar', 'Roles has been changed successfully!', 'success')
        }
    }
}
</script>

<style scoped>

</style>
