export const headerItems = [
    {
        sort_by: 'checkbox'
    },
    {
        sort_by: 'index'
    },
    {
        classes: 'custom-table__row-is_active',
        styles: 'justify-content: left; text-align: left;',
        sort_by: 'is_active',
        title: 'Status'
    },
    {
        classes: 'custom-table__row-name',
        styles: 'justify-content: left; text-align: left;',
        sort_by: 'lastname',
        title: 'Name'
    },
    {
        classes: 'custom-table__row-email',
        styles: 'justify-content: left; text-align: left;',
        sort_by: 'email',
        title: 'Email'
    },
    {
        classes: 'custom-table__row-roles',
        styles: 'justify-content: left; text-align: left;',
        sort_by: 'roles',
        title: 'Roles',
        hide_sort: true
    },
    {
        classes: 'custom-table__row-permissions',
        styles: 'justify-content: left; text-align: left;',
        sort_by: 'user_permissions',
        title: 'Permissions',
        hide_sort: true
    }
]
