export const UserFilters = {
    config: {
        rowsPerPage: true,
        paginationPage: true,
        search: true
    },
    lists: {
        fieldsList: [
            {
                value: 'firstname',
                text: 'First name'
            },
            {
                value: 'lastname',
                text: 'Last name'
            },
            {
                value: 'email',
                text: 'Email'
            }
        ]
    }
}
