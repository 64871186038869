export const toolbarSettings = [
    {
        name: 'delete',
        permission: 'users/manage'
    },
    {
        name: 'lock',
        permission: 'users/manage'
    },
    {
        name: 'unlock',
        permission: 'users/manage'
    }
]
